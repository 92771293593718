import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
// const ModernDash = Loadable(lazy(() => import('../views/dashboard/Modern')));
// const EcommerceDash = Loadable(lazy(() => import('../views/dashboard/Ecommerce')));

/* ****Apps***** */
// const Blog = Loadable(lazy(() => import('../views/apps/blog/Blog')));
// const BlogDetail = Loadable(lazy(() => import('../views/apps/blog/BlogPost')));
// const Contacts = Loadable(lazy(() => import('../views/apps/contacts/Contacts')));
// const Chats = Loadable(lazy(() => import('../views/apps/chat/Chat')));
// const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
// const Tickets = Loadable(lazy(() => import('../views/apps/tickets/Tickets')));
// const Ecommerce = Loadable(lazy(() => import('../views/apps/eCommerce/Ecommerce')));
// const EcommerceDetail = Loadable(lazy(() => import('../views/apps/eCommerce/EcommerceDetail')));
// const EcomProductList = Loadable(lazy(() => import('../views/apps/eCommerce/EcomProductList')));
// const EcomProductCheckout = Loadable(
//   lazy(() => import('../views/apps/eCommerce/EcommerceCheckout')),
// );
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/BigCalendar')));
// const UserProfile = Loadable(lazy(() => import('../views/apps/user-profile/UserProfile')));
// const Followers = Loadable(lazy(() => import('../views/apps/user-profile/Followers')));
// const Friends = Loadable(lazy(() => import('../views/apps/user-profile/Friends')));
// const Gallery = Loadable(lazy(() => import('../views/apps/user-profile/Gallery')));
// const Email = Loadable(lazy(() => import('../views/apps/email/Email')));

// ui components
// const MuiAlert = Loadable(lazy(() => import('../views/ui-components/MuiAlert')));
// const MuiAccordion = Loadable(lazy(() => import('../views/ui-components/MuiAccordion')));
// const MuiAvatar = Loadable(lazy(() => import('../views/ui-components/MuiAvatar')));
// const MuiChip = Loadable(lazy(() => import('../views/ui-components/MuiChip')));
// const MuiDialog = Loadable(lazy(() => import('../views/ui-components/MuiDialog')));
// const MuiList = Loadable(lazy(() => import('../views/ui-components/MuiList')));
// const MuiPopover = Loadable(lazy(() => import('../views/ui-components/MuiPopover')));
// const MuiRating = Loadable(lazy(() => import('../views/ui-components/MuiRating')));
// const MuiTabs = Loadable(lazy(() => import('../views/ui-components/MuiTabs')));
// const MuiTooltip = Loadable(lazy(() => import('../views/ui-components/MuiTooltip')));
// const MuiTransferList = Loadable(lazy(() => import('../views/ui-components/MuiTransferList')));
// const MuiTypography = Loadable(lazy(() => import('../views/ui-components/MuiTypography')));

// form elements
// const MuiAutoComplete = Loadable(
//   lazy(() => import('../views/forms/form-elements/MuiAutoComplete')),
// );
// const MuiButton = Loadable(lazy(() => import('../views/forms/form-elements/MuiButton')));
// const MuiCheckbox = Loadable(lazy(() => import('../views/forms/form-elements/MuiCheckbox')));
// const MuiRadio = Loadable(lazy(() => import('../views/forms/form-elements/MuiRadio')));
// const MuiSlider = Loadable(lazy(() => import('../views/forms/form-elements/MuiSlider')));
// const MuiDateTime = Loadable(lazy(() => import('../views/forms/form-elements/MuiDateTime')));
// const MuiSwitch = Loadable(lazy(() => import('../views/forms/form-elements/MuiSwitch')));

// forms
// const FormLayouts = Loadable(lazy(() => import('../views/forms/FormLayouts')));
// const FormCustom = Loadable(lazy(() => import('../views/forms/FormCustom')));
// const FormHorizontal = Loadable(lazy(() => import('../views/forms/FormHorizontal')));
// const FormVertical = Loadable(lazy(() => import('../views/forms/FormVertical')));
// const FormWizard = Loadable(lazy(() => import('../views/forms/FormWizard')));
// const FormValidation = Loadable(lazy(() => import('../views/forms/FormValidation')));
// const QuillEditor = Loadable(lazy(() => import('../views/forms/quill-editor/QuillEditor')));

// pages
// const RollbaseCASL = Loadable(lazy(() => import('../views/pages/rollbaseCASL/RollbaseCASL')));
// const Treeview = Loadable(lazy(() => import('../views/pages/treeview/Treeview')));
const Faq = Loadable(lazy(() => import('../views/pages/faq/Faq')));
// const Pricing = Loadable(lazy(() => import('../views/pages/pricing/Pricing')));
const AccountSetting = Loadable(
  lazy(() => import('../views/pages/account-setting/AccountSetting')),
);

const Coaches = Loadable(lazy(() => import('../views/pages/coaches/Coaches')));
const CoachesPackages = Loadable(
  lazy(() => import('../views/pages/coachesPackages/CoachesPackages')),
);
const Schedule = Loadable(lazy(() => import('../views/pages/schedule/Schedule')));
const LoyaltyProgram = Loadable(
  lazy(() => import('../views/pages/loyalty-program/LoyaltyProgram')),
);
const Reporting = Loadable(lazy(() => import('../views/pages/reporting/Reporting')));
const Coupons = Loadable(lazy(() => import('../views/pages/coupons/Coupons')));
const Discounts = Loadable(lazy(() => import('../views/pages/discounts/Discounts')));
const MalaebLive = Loadable(lazy(() => import('../views/pages/malaeb-live/MalaebLiveBookings')));
const ViewVideo = Loadable(lazy(() => import('../views/pages/malaeb-live/ViewVideo')));
const ManageStadium = Loadable(lazy(() => import('../views/pages/manage-stadium/ManageStadium')));
const Gems = Loadable(lazy(() => import('../views/pages/gems/Gems')));
const GemBreakdown = Loadable(lazy(() => import('../views/pages/gems/components/GemBreakdown')));
const Invoices = Loadable(lazy(() => import('../views/pages/billing/Billing')));
const OnlinePayments = Loadable(
  lazy(() => import('../views/pages/billing/components/OnlinePayments')),
);
const Tournaments = Loadable(lazy(() => import('../views/pages/tournaments/Tournaments')));
const Wallet = Loadable(lazy(() => import('../views/pages/wallet/Wallet')));
const SuccessPage = Loadable(lazy(() => import('../views/pages/success-page/SuccessPage')));
const ErrorPage = Loadable(lazy(() => import('../views/pages/error-page/ErrorPage')));

// charts
// const AreaChart = Loadable(lazy(() => import('../views/charts/AreaChart')));
// const CandlestickChart = Loadable(lazy(() => import('../views/charts/CandlestickChart')));
// const ColumnChart = Loadable(lazy(() => import('../views/charts/ColumnChart')));
// const DoughnutChart = Loadable(lazy(() => import('../views/charts/DoughnutChart')));
// const GredientChart = Loadable(lazy(() => import('../views/charts/GredientChart')));
// const RadialbarChart = Loadable(lazy(() => import('../views/charts/RadialbarChart')));
// const LineChart = Loadable(lazy(() => import('../views/charts/LineChart')));

// // tables
// const BasicTable = Loadable(lazy(() => import('../views/tables/BasicTable')));
// const EnhanceTable = Loadable(lazy(() => import('../views/tables/EnhanceTable')));
// const PaginationTable = Loadable(lazy(() => import('../views/tables/PaginationTable')));
// const FixedHeaderTable = Loadable(lazy(() => import('../views/tables/FixedHeaderTable')));
// const CollapsibleTable = Loadable(lazy(() => import('../views/tables/CollapsibleTable')));
// const SearchTable = Loadable(lazy(() => import('../views/tables/SearchTable')));

// widget
const WidgetCards = Loadable(lazy(() => import('../views/widgets/cards/WidgetCards')));
const WidgetBanners = Loadable(lazy(() => import('../views/widgets/banners/WidgetBanners')));
const WidgetCharts = Loadable(lazy(() => import('../views/widgets/charts/WidgetCharts')));

// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/auth2/ForgotPassword2')),
);
const TwoSteps = Loadable(lazy(() => import('../views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('../views/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

// landingpage
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));
const TournamentOrganizers = Loadable(
  lazy(() => import('../views/pages/tournamentOrganizers/TournamentOrganizers')),
);
const TournamentTeams = Loadable(
  lazy(() => import('../views/pages/tournamentTeams/TournamentTeams')),
);
const TournamentProfile = Loadable(
  lazy(() => import('../views/pages/tournaments/components/TournamentInfo/TournamentProfile')),
);

const AuthWrapper = (children: React.ReactNode) => {
  // const { hasUsers } = useAuth(); // Assume this hook provides user information
  const user = useSelector((state: AppState) => state.authReducer?.user);

  if (!user) {
    return <Navigate to="/auth/login" replace />;
  }

  return <FullLayout>{children}</FullLayout>;
};

const Router = [
  {
    path: '/',
    element: <AuthWrapper />,
    children: [
      { path: '/', element: <Navigate to="/pages/schedule" /> },
      // { path: '/dashboards/modern', exact: true, element: <ModernDash /> },
      // { path: '/dashboards/ecommerce', exact: true, element: <EcommerceDash /> },
      // { path: '/apps/contacts', element: <Contacts /> },
      // { path: '/apps/blog/posts', element: <Blog /> },
      // { path: '/apps/blog/detail/:id', element: <BlogDetail /> },
      // { path: '/apps/chats', element: <Chats /> },
      // { path: '/apps/email', element: <Email /> },
      // { path: '/apps/notes', element: <Notes /> },
      // { path: '/apps/tickets', element: <Tickets /> },
      // { path: '/apps/ecommerce/shop', element: <Ecommerce /> },
      // { path: '/apps/ecommerce/eco-product-list', element: <EcomProductList /> },
      // { path: '/apps/ecommerce/eco-checkout', element: <EcomProductCheckout /> },
      // { path: '/apps/ecommerce/detail/:id', element: <EcommerceDetail /> },
      // { path: '/apps/followers', element: <Followers /> },
      // { path: '/apps/friends', element: <Friends /> },
      // { path: '/apps/gallery', element: <Gallery /> },
      // { path: '/user-profile', element: <UserProfile /> },
      { path: '/apps/calendar', element: <Calendar /> },
      // { path: '/ui-components/alert', element: <MuiAlert /> },
      // { path: '/ui-components/accordion', element: <MuiAccordion /> },
      // { path: '/ui-components/avatar', element: <MuiAvatar /> },
      // { path: '/ui-components/chip', element: <MuiChip /> },
      // { path: '/ui-components/dialog', element: <MuiDialog /> },
      // { path: '/ui-components/list', element: <MuiList /> },
      // { path: '/ui-components/popover', element: <MuiPopover /> },
      // { path: '/ui-components/rating', element: <MuiRating /> },
      // { path: '/ui-components/tabs', element: <MuiTabs /> },
      // { path: '/ui-components/tooltip', element: <MuiTooltip /> },
      // { path: '/ui-components/transfer-list', element: <MuiTransferList /> },
      // { path: '/ui-components/typography', element: <MuiTypography /> },
      // { path: '/pages/casl', element: <RollbaseCASL /> },
      // { path: '/pages/treeview', element: <Treeview /> },
      // { path: '/pages/pricing', element: <Pricing /> },
      { path: '/pages/faq', element: <Faq /> },
      { path: '/pages/account-settings', element: <AccountSetting /> },
      // { path: '/tables/basic', element: <BasicTable /> },
      // { path: '/tables/enhanced', element: <EnhanceTable /> },
      // { path: '/tables/pagination', element: <PaginationTable /> },
      // { path: '/tables/fixed-header', element: <FixedHeaderTable /> },
      // { path: '/tables/collapsible', element: <CollapsibleTable /> },
      // { path: '/tables/search', element: <SearchTable /> },
      // { path: '/forms/form-elements/autocomplete', element: <MuiAutoComplete /> },
      // { path: '/forms/form-elements/button', element: <MuiButton /> },
      // { path: '/forms/form-elements/checkbox', element: <MuiCheckbox /> },
      // { path: '/forms/form-elements/radio', element: <MuiRadio /> },
      // { path: '/forms/form-elements/slider', element: <MuiSlider /> },
      // { path: '/forms/form-elements/date-time', element: <MuiDateTime /> },
      // { path: '/forms/form-elements/switch', element: <MuiSwitch /> },
      // { path: '/forms/form-elements/switch', element: <MuiSwitch /> },
      // { path: '/forms/form-layouts', element: <FormLayouts /> },
      // { path: '/forms/form-custom', element: <FormCustom /> },
      // { path: '/forms/form-wizard', element: <FormWizard /> },
      // { path: '/forms/form-validation', element: <FormValidation /> },
      // { path: '/forms/form-horizontal', element: <FormHorizontal /> },
      // { path: '/forms/form-vertical', element: <FormVertical /> },
      // { path: '/forms/quill-editor', element: <QuillEditor /> },
      // { path: '/charts/area-chart', element: <AreaChart /> },
      // { path: '/charts/line-chart', element: <LineChart /> },
      // { path: '/charts/gredient-chart', element: <GredientChart /> },
      // { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
      // { path: '/charts/column-chart', element: <ColumnChart /> },
      // { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
      // { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
      { path: '/widgets/cards', element: <WidgetCards /> },
      { path: '/widgets/banners', element: <WidgetBanners /> },
      { path: '/widgets/charts', element: <WidgetCharts /> },
      { path: '/pages/coaches', element: <Coaches /> },
      { path: '/pages/discounts', element: <Discounts /> },
      { path: '/pages/coupons', element: <Coupons /> },
      { path: '/pages/:stadiumId/coaches/:id/packages', element: <CoachesPackages /> },
      {
        path: '/pages/:stadiumId/tournaments/:status/:id/organizers',
        element: <TournamentOrganizers />,
      },
      { path: '/pages/:stadiumId/tournaments/:status/:id/teams', element: <TournamentTeams /> },
      { path: '/pages/:stadiumId/tournaments/:status/:id', element: <TournamentProfile /> },
      { path: '/pages/schedule', element: <Schedule /> },
      { path: '/pages/loyalty-program', element: <LoyaltyProgram /> },
      { path: '/pages/reporting', element: <Reporting /> },
      { path: '/pages/malaeb-live/malaeb-live-bookings', element: <MalaebLive /> },
      { path: '/pages/malaeb-live/videos', element: <ViewVideo /> },
      { path: '/pages/malaeb-live/video/:bookingId', element: <ViewVideo /> },
      { path: '/pages/manage-stadium', element: <ManageStadium /> },
      { path: '/pages/billing/invoices', element: <Invoices /> },
      { path: '/pages/billing/online-payments', element: <OnlinePayments /> },
      { path: '/pages/gems/manage-gems', element: <Gems /> },
      { path: '/pages/gems/breakdown', element: <GemBreakdown /> },
      { path: '/pages/tournaments/:status', element: <Tournaments /> },
      { path: '/pages/wallet', element: <Wallet /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/register2', element: <Register2 /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/two-steps', element: <TwoSteps /> },
      { path: '/auth/two-steps2', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '/charge-wallet/failure/:id', element: <ErrorPage /> },
      { path: '/charge-wallet/success/:id', element: <SuccessPage /> },
      { path: '/landingpage', element: <Landingpage /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
