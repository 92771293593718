import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<LoginResponse, LoginBody>({
    query: (body) => ({
      url: '/v4/pitch-owner/auth/password/forgot',
      method: 'POST',
      body,
    }),
  });

interface LoginBody {
  email: string;
}

interface LoginResponse {
  data: {
    message: string;
  };
}
