import { FC, useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { dispatch, useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import { AppState } from './store/Store';
import { Toaster } from 'react-hot-toast';

import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import i18n from './utils/i18n';

import { useLazyMeQuery } from './services/auth';
import { setUser } from './store/apps/auth/AuthSlice';

import { AlertDialogProvider } from './hooks/useAlertDialog';
import { useLazyFetchPitchesQuery, useLazyFetchStadiumsQuery } from './services/stadiums';
import {
  setFemaleFriendly,
  setPitch,
  setPitches,
  setSelectedStadium,
  setStadiums,
} from './store/apps/config/ConfigSlice';
import { Price } from './types/apps/stadium';

import './Global.css';
import { PusherProvider } from './contexts/PusherContext';
import { useLazyGetWalletBalanceQuery } from './services/wallet';
import { setWallet } from './store/apps/wallet/WalletSlice';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  const [fetchUser] = useLazyMeQuery();

  const [fetchStadiums] = useLazyFetchStadiumsQuery();
  const [fetchPitches] = useLazyFetchPitchesQuery();
  const [fetchWallet] = useLazyGetWalletBalanceQuery();

  const customizer = useSelector((state: AppState) => state.customizer);
  const user = useSelector((state: AppState) => state.authReducer.user);
  const selectedStadium = useSelector((state: AppState) => state.configReducer.selectedStadium);

  useEffect(() => {
    if (user) {
      fetchUser('').then((result) => {
        dispatch(
          setUser({
            data: {
              token: user.token,
              data: result?.data?.data,
            },
          }),
        );
      });
    }

    if (customizer.activeDir === 'ltr') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ar');
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchStadiums(undefined, undefined).then((result) => {
        const stadiums = result?.data?.data;
        dispatch(setStadiums(stadiums));

        if (!selectedStadium && Array.isArray(stadiums) && stadiums?.length > 0) {
          dispatch(setSelectedStadium(stadiums?.[0]));
        }
      });
      fetchWallet().then((result) => {
        dispatch(setWallet(result.data?.data));
      });
    }
  }, [user]);

  useEffect(() => {
    if (selectedStadium?.id && user) {
      fetchPitches({
        stadium_id: selectedStadium?.id,
      }).then((result) => {
        const pitches = result.data?.data;
        dispatch(setPitches(pitches));

        if (pitches && pitches?.length > 0) {
          dispatch(setPitch(pitches?.[0]));
          const femaleFriendly: Price[] = pitches[0]?.prices?.filter(
            (item) => item.female_friendly,
          );
          dispatch(setFemaleFriendly(femaleFriendly));
        }
      });
    }
  }, [selectedStadium, dispatch, fetchPitches]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir || 'l'}>
        <PusherProvider>
          <AlertDialogProvider>
            <CssBaseline />
            <ScrollToTop>{routing}</ScrollToTop>
            <Toaster />
          </AlertDialogProvider>
        </PusherProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
