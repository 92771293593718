import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, body>({
    query: (body) => ({
      url: `/v3/pitch-owner-app/stadiums/${body?.stadiumId}/dayoff/${body?.id}`,
      method: 'Delete',
      body,
    }),
  });

export interface body {
  stadiumId: number;
  id: number;
}
