import { api } from 'src/services'

import login from './login'
import me from './me'
import forgetpassword from './forgetpassword'

export const authApis = api.injectEndpoints({
  endpoints: build => ({
    login: login(build),
    me: me(build),
    forgetpassword: forgetpassword(build)
  }),
  overrideExisting: true,
})

export const {
  useLoginMutation,
  useLazyMeQuery,
  useForgetpasswordMutation
} = authApis
